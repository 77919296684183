<!-- 字典项管理页面 -->
<template>
  <div class="dictionariesManagement">
    <div class="top-area clr">
      <Button type="primary" @click="currentData = {},modelShow = true">新增</Button>
      <!-- <Input style="width:240px" class="fr" search placeholder="请输入..." /> -->
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="dicIcon">
          <img style="width:auto;height:40px;" :src="row.dicIcon" alt />
        </template>
      </Table>
    </div>
    <Modal v-model="modelShow" :title="titleName" width="600">
      <addModel ref="addModel" v-if="modelShow" :titleName="titleName" :currentData="currentData" />
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="save">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import addModel from './components/addModel.vue'
import { getDictionaryList, addDictionary } from '@/api/dictionariesManagement.js'

export default {
  components: {
    addModel
  },
  data() {
    return {
      tableHeight: window.innerHeight - 220,
      columns: [
        {
          title: 'id',
          key: 'id',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '字典项名称',
          key: 'dicName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '字典项code',
          key: 'dicCode',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '字典项type',
          key: 'dicType',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '图标',
          key: 'dicIcon',
          slot: 'dicIcon'
        },
        {
          title: '创建时间',
          key: 'createTime',
          width: 200
        },
      ],
      dataList: [],
      modelShow: false,
      titleName: '新增',
      currentData: {},
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    findPageDataList() {
      getDictionaryList({ size: this.size, pageIndex: this.current }).then(res => {
        if (res.success) {
          this.dataList = res.result
        }
      })
    },
    save() {
      let params = this.$refs.addModel.formData
      this.$refs.addModel.$refs.addForm.validate((valid) => {
        if (valid) {
          addDictionary(params).then(res => {
            if (res.success) {
              this.$Message.success('保存成功！')
              this.findPageDataList()
              this.modelShow = false
            }
          })
        }
      })
    },
  }
}

</script>
<style lang='scss' scoped>
.dictionariesManagement {
}
</style>