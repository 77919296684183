<template>
  <div class="addModel">
    <Form ref="addForm" :model="formData" :rules="ruleValidate" :label-width="120">
      <FormItem label="字典项名称：" prop="dicName">
        <Input v-model="formData.dicName" style="width:400px" maxlength="50"></Input>
      </FormItem>
      <FormItem label="字典项code：" prop="dicCode">
        <Input v-model="formData.dicCode" style="width:400px" maxlength="50"></Input>
      </FormItem>
      <FormItem label="字典项type：" prop="dicType">
        <Input v-model="formData.dicType" style="width:400px" maxlength="50"></Input>
      </FormItem>
      <FormItem label="图标：">
        <Upload
          action="/api/upload/resources"
          :data="dataObj"
          :headers="headersData"
          :before-upload="handleUpload"
          :on-success="(res)=>{handleSuccess(res,'dicIcon')}"
        >
          <div class="door-photograph">
            <Icon type="md-add" size="45" v-if="!formData.dicIcon" />
            <img :src="formData.dicIcon" v-if="formData.dicIcon" />
          </div>
        </Upload>
        <!-- <p style="color:#c0c4cc">用户默认头像，后台添加用户以及用户登录的默认头像显示，尺寸(80*80)</p> -->
      </FormItem>
    </Form>
  </div>
</template>

<script>
import { getSessionStore } from '@/libs/storage';

export default {
  props: {
    currentData: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      dataObj: {
        userId: this.$store.getters.userInfo.id
      },
      headersData: {
        'X-Token': getSessionStore('accessToken')
      },
      formData: {
        dicName: '',
        dicCode: '',
        dicType: '',
        dicIcon: '',
      },
      ruleValidate: {
        dicName: [
          { required: true, message: '请输入字典项名称', trigger: 'blur' }
        ],
        dicCode: [
          { required: true, message: '请输入字典项code', trigger: 'blur' }
        ],
        dicType: [
          { required: true, message: '请输入字典项type', trigger: 'blur' }
        ],
      }
    }
  },
  created() {
    if (this.titleName != '新增') {
      this.formData = this.currentData
    }
  },
  methods: {
    handleUpload(file) {
      console.log(file)
    },
    handleSuccess(res, type) {
      this.formData[type] = res.result;
    },
  }
}
</script>

<style lang='scss'>
.addModel {
  .door-photograph {
    display: inline-block;
    margin-left: 8px;
    width: 180px;
    height: 120px;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    line-height: 120px;
    img {
      width: 100%;
      height: 100%;
      display: inline-block;
      vertical-align: top;
    }
  }
  .ivu-upload-list {
    display: none;
  }
  .demo-upload-list {
    display: inline-block;
    width: 180px;
    height: 120px;
    text-align: center;
    line-height: 120px;
    border: 1px solid transparent;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
    margin-right: 4px;
  }
  .demo-upload-list img {
    width: 100%;
    height: 100%;
  }
  .demo-upload-list-cover {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .demo-upload-list:hover .demo-upload-list-cover {
    display: block;
  }
  .demo-upload-list-cover i {
    color: #fff;
    font-size: 30px;
    cursor: pointer;
    margin: 0 2px;
  }
}
</style>