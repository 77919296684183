import { postRequest } from '@/libs/axios';


// 字典项列表
export const getDictionaryList = (params) => {
    return postRequest('/api/sys/dictionary/list', params)
}

// 字典项列表
export const addDictionary = (params) => {
    return postRequest('/api/sys/dictionary/add', params)
}